// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-carreiras-js": () => import("./../../../src/pages/carreiras.js" /* webpackChunkName: "component---src-pages-carreiras-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modelo-js": () => import("./../../../src/pages/modelo.js" /* webpackChunkName: "component---src-pages-modelo-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-reserve-seu-squad-js": () => import("./../../../src/pages/reserve-seu-squad.js" /* webpackChunkName: "component---src-pages-reserve-seu-squad-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

